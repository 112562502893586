import { extend, localize } from 'vee-validate'
import {
  numeric as rule_numeric,
  required as rule_required,
  email as rule_email,
  min as rule_min,
  max as rule_max,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_spaces as rule_alpha_spaces,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  max_value as rule_max_value,
  min_value as rule_min_value
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorPhoneNumber,
  validatorDecimalPlaces,
  validatorMinNumber,
  validatorMaxNumber,
  validatorLessThan,
  validatorGreaterThan,
  validatorTAX,
  validatorTAXNew,
  validatorIsNumber,
  validatorImageFile,
  validatorIP,
  validatorUSB, validatorLength, validatorTAXSlovenia, validatorMustBePositive
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////
export const required = extend('required', rule_required)

export const numeric = extend('numeric', rule_numeric)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const max = extend('max', rule_max)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const alpha_spaces = extend('alpha_spaces', rule_alpha_spaces)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha_num', rule_alpha_num)

export const length = extend('length', {
  validate: validatorLength,
  message: (value, rule) => {
    return `Polje ${value} mora vsebovati ${rule[0]} znakov.`
  }
})

export const maxValue = extend('max_value', rule_max_value)

export const minValue = extend('min_value', rule_min_value)

export const imageFile = extend('image_file', {
  validate: validatorImageFile,
  message: 'Datoteka mora biti tipa .webp, .jpg, .jpeg, .png ali .gif.'

})
export const timeBefore = extend('time_before', {
  validate: validatorLessThan,
  message: (value, time) => {
    return `Polje Začetni čas mora vsebovati uro, ki je pred ${time[0]}.`
  }
})

export const timeAfter = extend('time_after', {
  validate: validatorGreaterThan,
  message: (value, time) => {
    return `Polje Končni čas mora vsebovati uro, ki je za ${time[0]}.`
  }
})
export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!'
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!'
})

export const password = extend('password', {
  validate: validatorPassword,
  message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit'
})

export const ip = extend('ip', {
  validate: validatorIP,
  message: 'Polje {_field_} mora biti veljaven IP naslov'
})

export const usb = extend('usb', {
  validate: validatorUSB,
  message: 'Polje {_field_} mora biti veljaven USB naslov'
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid'
})

export const PhoneNumber = extend('phone_number', {
  validate: validatorPhoneNumber,
  message: 'Telefonska številka ni veljavna'
})

export const isNumber = extend('is_number', {
  validate: validatorIsNumber,
  message: () => {
    return  'Polje lahko vsebuje samo števila.'
  }
})
export const tax = extend('tax', {
  validate: validatorTAX,
  message: (value, rule) => {
    return `${rule._value_} ni veljavna davčna številka.`
  }
})

export const tax_slovenian = extend('tax_slovenian', {
  validate: validatorTAXSlovenia,
  message: (value, rule) => {
    return `${rule._value_} ni veljavna davčna številka.`
  }
})

export const taxNew = extend('taxNew', {
  validate: validatorTAXNew,
  message: (value, rule) => {
    return `${rule._value_} ni veljavna davčna številka.`
  }
})
export const minNumber = extend('min_number', {
  validate: validatorMinNumber,
  message: (value, rule) => {
    return  `Polje ${rule._field_} mora biti več kot ${rule[0]}.` //changed to display correctly min val in error
    //return  `Polje ${rule._field_} mora biti več kot ${rule._value_}.`
  }
})
export const maxNumber = extend('max_number', {
  validate: validatorMaxNumber,
  message: (value, rule) => {
    return  `Polje ${rule._field_} mora biti manj ali enako kot ${rule[0]}.`
  }
})
export const decimalCustom = extend('decimalCustom', {
  validate: validatorDecimalPlaces,
  message: (value, rule) => {
    return  `Polje ${rule._field_} lahko vsebuje maksimalno ${rule[0]} decimalko/i.`
  }
})

export const mustBePositive = extend('mustBePositive', {
  validate: validatorMustBePositive,
  message: (value, rule) => {
    return  `Polje ${rule._field_} mora biti pozitivno število.`
  }
})


// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password'
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?'
      }
    }
  },
  sl: {
    messages: {
      'alpha': 'Polje {_field_} lahko vsebuje le črkovne znake',
      'alpha_dash': 'Polje {_field_} lahko vsebuje le alfanumerične znake kot tudi vezaje in podčrtaje',
      'alpha_num': 'Polje {_field_} lahko vsebuje le alfanumerične znake',
      'alpha_spaces': 'Polje {_field_} lahko vsebuje le črkovne znake in presledke',
      'between': 'Polje {_field_} mora biti med {min} in {max}',
      'confirmed': 'Polje {_field_} se ne ujema',
      'digits': 'Vrednost polja {_field_} mora biti numerična in vsebovati natančno {length} številk',
      'dimensions': 'Slika {_field_} mora biti široka {width} slikovnih točk in visoka {height} slikovnih točk',
      'email': 'Vrednost polja {_field_} mora biti ustrezen e-naslov',
      'excluded': 'Polje {_field_} mora biti ustrezne vrednosti',
      'ext': 'Datoteka polja {_field_} mora biti ustrezna',
      'image': 'Datoteka polja {_field_} mora biti slika',
      'oneOf': 'Polje {_field_} mora biti ustrezne vrednosti',
      'max': 'Dolžina polja {_field_} ne sme biti večja od {length} znakov',
      'max_value': 'Vrednost polja {_field_} mora biti {max} ali manj',
      'mimes': 'Datoteka polja {_field_} mora biti ustreznega tipa',
      'min': 'Dolžina polja {_field_} mora biti vsaj {length} znakov',
      'min_value': 'Vrednost polja {_field_} mora biti {min} ali več',
      'numeric': 'Polje {_field_} lahko vsebuje le numerične znake',
      'regex': 'Vrednost polja {_field_} ni v ustreznem formatu',
      'required': 'Polje {_field_} je obvezno',
      'required_if': 'Polje {_field_} je obvezno',
      'size': 'Velikost datoteke {_field_} mora biti manjša kot {size}KB',
      'double': 'Polje {_field_} mora biti veljavno decimalno mesto',
      'at_least_one_uppercase': 'Polje {_field_} mora vsebovati vsaj eno veliko črko',
      'at_least_one_digit': 'Polje {_field_} mora vsebovati vsaj eno številko'
    }
  }

  
})
// ////////////////////////////////////////////////////////
// NOTE:
// Quasar validation for reference only
// Remove this note once development is finished and make sure to
// to convert all of them in veevalidate version
// ////////////////////////////////////////////////////////

// export const required = (val) => {
//   return (val && val.length > 0) || '*Field is required'
// }

// export const required_obj = (obj) => {
//   if (obj === null || obj === undefined) return '*Field is required'
//   return (Object.entries(obj).length > 0 && obj.constructor === Object) || '*Field is required'
// }

// export const no_blank_spaces_arr = (arr) => {
//   return arr.every(val => (val.trim() && val.trim().length > 0)) || 'Blank Spaces are not allowed'
// }

// export const url = val => {
//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // Used
//   // https://stackoverflow.com/questions/4314741/url-regex-validation

//   // Other
//   // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
//   // https://www.w3resource.com/javascript-exercises/javascript-regexp-exercise-9.php
//   // https://www.geeksforgeeks.org/how-to-validate-url-using-regular-expression-in-javascript/

//   /* eslint-disable no-useless-escape */
//   const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
//   /* eslint-enable no-useless-escape */
//   return re.test(val) || 'URL is invalid'
// }

// export const date = val => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   // https://github.com/quasarframework/quasar/blob/dev/ui/src/utils/patterns.js
//   return /^-?[\d]+\/[0-1]\d\/[0-3]\d$/.test(val) || 'Date is invalid'
// }

// export const max = (val, max) => {

//   // If blank return
//   if (val === undefined || val === null) return true

//   return val.length <= max || `More than ${max} characters are not allowed`
// }

// export const max_arr = (val, max) => {
//   return val.length <= max || `More than ${max} values are not allowed`
// }

// export const min = (val, min) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   return val.length >= min || `Minimum ${min} characters are required`
// }

// export const num_range = (val, min, max) => {

//   // If blank return
//   if (val === undefined || val === null || val.length === 0) return true

//   const msg = 'Value is invalid'
//   if (min === null) return val <= max || msg
//   else if (max === null) return val >= min || msg
//   else return (val >= min && val <= max) || msg
// }
