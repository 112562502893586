import {countDecimals} from '@/libs/helpers'

export const validatorImageFile = (file) => {
  const ext = file.name.substring(file.name.length - 4, file.name.length)
  return ext === 'webp' || ext === '.jpg' || ext === 'jpeg' || ext === '.png' || ext === '.gif'

}
export const validatorLessThan = (value, value2) => {
  if (value > value2) {
    return false
  }
  return true
}
export const validatorLength = (value, value2) => {
  if (!value2 || value2.length <= 0) {
    return false
  }

  const length = Number(value2[0])
  if (value.length !== length) {
    return false
  }
  return true
}


export const validatorGreaterThan = (value, value2) => {
  if (value < value2) {
    return false
  }
  return true
}
export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorIP = ip => {
  /* eslint-disable no-useless-escape */
  const regExp = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d):(?:6553[0-5]|655[0-2]\d|65[0-4]\d{2}|6[0-4]\d{3}|[1-5]\d{4}|\d{1,4})$/
  /* eslint-enable no-useless-escape */
  const validIP = regExp.test(ip)
  return validIP
}

export const validatorUSB = usb => {
  /* eslint-disable no-useless-escape */
  const regExp = /^[0-9A-Fa-f]{4}\/[0-9A-Fa-f]{4}\/\d{2}\/\d{2}$/
  /* eslint-enable no-useless-escape */
  const validUSB = regExp.test(usb)
  return validUSB
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorPhoneNumber = phoneNumber => {
  /* eslint-disable no-useless-escape */
  //Remove spaces
  phoneNumber = phoneNumber.replace(/\s/g, '')

  //With prefix +386
  const regFirst = /^\+(386)([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/
  //Normal 051 XXX XXX
  const regSecond = /^0([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/
  //With prefix 00386
  const regThird = /^00386([1-7][0-9]{7}|([347]0|[3457]1|6[4589]){6})$/


  /* eslint-enable no-useless-escape */
  const validatePhoneNumber = regFirst.test(phoneNumber) | regSecond.test(phoneNumber) | regThird.test(phoneNumber)
  return validatePhoneNumber
}

export const validatorDecimalPlaces = (val, params) => {
  const numberOfDecimalsPlaces = params[0]

  return countDecimals(val) <= numberOfDecimalsPlaces
}

export const validatorMustBePositive = (val, params) => {
  const mustBePositive = params[0]

  if (mustBePositive) return Number(val) >= 0
  return true
}

export const validatorMinNumber = (value, number) => {
  if (value) {
    value = String(value).replace(',', '.')
  }

  if (!number) return false
  if (value > Number(number[0])) {
    return true
  }
  return false
}

export const validatorMaxNumber = (value, number) => {
  if (!number) return false
  if (value <= Number(number[0])) {
    return true
  }
  return false
}

export const validatorIsNumber = (value) => {
  if (!value) return false
  if (isNaN(value)) {
    return false
  }
  return true
}

export const validatorTAX = (val) => {
  if (!val) {
    return
  }

  val = val.toUpperCase()
  if (val.startsWith('SI', 0)) {
    val = val.slice(2)

    if (!val || val.length !== 8 || val.split().every(c => isNaN(Number(c)))) return

    let sum = 0
    for (let i = 0; i < 7; i++) {
      sum += Number(val[i] * (8 - i))
    }
    let control = 11 - (sum % 11)
    if (control === 10 || control === 11) control = 0
    return val[7] === String(control)
  }

  const regex = /[A-Ža-ž0-9]/
  return regex.test(val)

}

export const validatorTAXSlovenia = (val) => {
  if (!val) {
    return
  }

  if (!val || val.length !== 8 || val.split().every(c => isNaN(Number(c)))) return

  let sum = 0
  for (let i = 0; i < 7; i++) {
    sum += Number(val[i] * (8 - i))
  }
  let control = 11 - (sum % 11)
  if (control === 10 || control === 11) control = 0
  return val[7] === String(control)
}

export const validatorTAXNew = (val) => {
  if (!val) {
    return
  }

  val = val.toUpperCase()
  if (val.startsWith('SI', 0)) {
    val = val.slice(2)
  }

  if (!val || val.length !== 8) return
  if (val.split().every(c => isNaN(Number(c)))) return

  let sum = 0
  for (let i = 0; i < 7; i++) {
    sum += Number(val[i] * (8 - i))
  }
  let control = 11 - (sum % 11)
  if (control === 10 || control === 11) control = 0
  return val[7] === String(control)
}

