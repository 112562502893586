<template>
    <div class="">
        <span v-if="params.button === 'priceList'">
            <b-button v-if="!params.noDelete"  class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
        </span>
        <span v-else-if="params.button === 'deleteBtn'">
            <b-button class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
        </span>
        <span v-else-if="params.button === 'ViewBill'">
            <b-button v-if="params.data.is_invoice" class="m-0 button" variant="secondary" @click="generatePDF">{{ $t('invoices.view') }}</b-button>
            <b-button v-else class="m-0 button" variant="secondary" @click="$router.push({name: 'preview_bill', params:{bill_id: params.data.id}})">{{ $t('general.view_bill') }}</b-button>
        </span>
        <span v-else-if="params.button === 'MakeCopy'">
            <b-button v-if="!params.data.reversal && !params.data.has_reversal && $hasPermission($permissions.InvoicesReversal)" class="mr-1 m-0 button" variant="warning" @click="refundIssuedInvoice">{{ $t('general.refund') }}</b-button>
            <b-button v-if="!params.data.reversal && !params.data.has_reversal" class="mr-1 m-0 button" variant="secondary" @click="$router.push({name: 'edit_invoice', params:{invoice_id: params.data.id}})">{{ $t('general.edit_invoice') }}</b-button>
            <b-button class="mr-1 m-0 button" variant="primary" @click="generatePDF">{{ $t('general.make_copy') }}</b-button>
        </span>
        <span v-else-if="params.button === 'Temporary'">
            <b-button class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
            <b-button class="mr-1 m-0 button" :variant="params.data.confirmed?'warning':'success'" @click="editRow" style="width: 55px;">
                <span v-if="params.data.confirmed"> {{ $t('general.edit') }}</span>
                <span v-else> {{ $t('general.accept') }}</span>
            </b-button>
            <b-button v-if="params.data.confirmed" class="m-0 button" variant="secondary" @click="viewPrintout">{{ $t('general.view_printout') }}</b-button>
        </span>
        <span v-else-if="params.button === 'ViewDetails'">
            <b-button class="m-0 button" variant="primary" @click="viewDetails">{{$t('general.details')}}</b-button>
        </span>
        <span v-else-if="params.button === 'Logo'">
            <b-button  :disabled="params.data.status !== 2" class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
            <b-button  :disabled="params.data.status !== 2"  class="mr-1 m-0 button" variant="secondary" @click="editRow">{{ $t('general.preview') }}</b-button>
        </span>
        <span v-else-if="params.button === 'LogoNoPermission'">
            <b-button  :disabled="params.data.status !== 2"  class="mr-1 m-0 button" variant="secondary" @click="editRow">{{ $t('general.preview') }}</b-button>
        </span>
        <span v-else-if="params.button === 'chooseLogo'">
            <b-button class="mr-1 m-0 button" variant="success" @click="confirm">{{ $t('general.accept') }}</b-button>
            <b-button class="mr-1 m-0 button" variant="secondary" @click="editRow">{{ $t('general.preview') }}</b-button>
        </span>
        <span v-else-if="params.button === 'Clone'">
            <b-button v-if="params.data.disabled" class="mr-1 m-0 button" variant="success" @click="enable">{{ $t('general.enable') }}</b-button>
            <b-button v-else class="mr-1 m-0 button" variant="danger" @click="disable">{{ $t('general.disable') }}</b-button>
            <b-button v-if="!params.data.disabled" class="mr-1 m-0 button" variant="warning" @click="editRow">{{ $t('general.edit') }}</b-button>
        </span>
        <span v-else-if="params.button === 'ViewTables'">
            <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
            <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" class="mr-1 m-0 button" variant="warning" @click="editRow">{{ $t('general.edit') }}</b-button>
            <b-button class="m-0 button" variant="secondary" @click="viewTables">{{ $t('general.view_room') }}</b-button>
        </span>
        <span v-else-if="params.button === 'ViewStock'">
            <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
            <b-button v-if="$hasPermission($permissions.ConfigurationWrite)" class="mr-1 m-0 button" variant="warning" @click="editRow">{{ $t('general.edit') }}</b-button>
            <b-button class="m-0 button" variant="secondary" @click="$router.push({name: 'warehouse_stock', params:{warehouse_id: params.data.id}})">{{ $t('general.view_warehouse_stock') }}</b-button>
        </span>
        <span v-else>
            <b-button class="mr-1 m-0 button" variant="danger" @click="deleteRow">{{ $t('general.delete') }}</b-button>
            <b-button class="mr-1 m-0 button" variant="warning" @click="editRow">{{ $t('general.edit') }}</b-button>
        </span>
        <span v-if="params.button === 'viewPrintout'">
            <b-button class="m-0 button" variant="secondary" @click="viewPrintout">{{ $t('general.view_printout') }}</b-button>
        </span>
        <span v-if="params.button === 'Confirm'">
            <!--            <b-button v-if="params.data.confirmed_with_furs" class="m-0 button" variant="secondary" @click="confirmBusinessUnitOnFurs(true)">{{ $t('general.close') }}</b-button>-->
            <b-button v-if="!params.data.confirmed_with_furs" class="m-0 button" variant="success" @click="confirmBusinessUnitOnFurs(false)">{{ $t('general.accept') }}</b-button>
        </span>
        <span v-if="params.button === 'ChangeOrder'">
            <b-button class="m-0 button" variant="success" @click="changeOrder">{{ $t('general.change_order') }}</b-button>
        </span>
        <span v-if="params.button === 'Clone'">
            <b-button class="m-0 button" variant="success" @click="clone"><feather-icon icon="CopyIcon"/></b-button>
        </span>
    </div>
</template>

<script>
    import { BButton } from 'bootstrap-vue'
    export default {
        components: {BButton},
        data() {
            return {
                value: null
            }
        },
        methods: {
            viewTables() {
                this.params.context.viewTables(this.params.data.id)
            },
            refundIssuedInvoice() {
                this.params.context.refundIssuedInvoice(this.params.data)
            },
            deleteRow() {
                if (this.params.removeObject) {
                    this.params.context.deleteRow(this.params.data)
                } else {
                    this.params.context.deleteRow(this.params.data.id)
                }
            },
            editRow() {
                this.params.context.editRow(this.params.data, this.params.node.id)
            },
            viewTemporaryFile() {
                this.params.context.viewTemporaryFile(this.params.data)
            },
            viewPrintout() {
                this.params.context.viewPrintout(this.params.data, this.params.node.id)
            },
            itemAdded(id) {
                this.params.context.itemAdded(id)
            },
            confirmBusinessUnitOnFurs(isAlreadyConfirmed) {
                this.params.context.confirmBusinessUnitOnFurs(this.params.data.id, isAlreadyConfirmed)
            },
            changeOrder() {
                this.params.context.changeOrder(this.params.data.id)
            },
            viewDetails() {
                this.params.context.viewDetails(this.params.data)
            },
            clone() {
                this.params.context.clone(this.params.data, this.params.node.id)
            },
            enable() {
                this.params.context.enable(this.params.data, this.params.node.id)
            },
            disable() {
                this.params.context.disable(this.params.data, this.params.node.id)
            },
            async generatePDF() {
                // this.$http({
                //     url: `/api/client/v1/invoices/pdf/${this.params.data.id}`,
                //     method: 'PUT',
                //     responseType: 'blob' // important
                // }).then((response) => {
                //     const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}))
                //
                //     const link = document.createElement('a')
                //     link.href = url
                //     link.setAttribute('target', '_blank')
                //     link.setAttribute('download', 'TEST')
                //     document.body.appendChild(link)
                //     link.click()
                // }).catch(() => {
                //     this.$printError(this.$t('print.error.on_file_download'))
                // })
                window.open(`/api/client/v1/invoices/pdf/${this.params.data.id}`)
            }
        }
    }
</script>

<style scoped>
.button {
  padding: 0.4rem;
}
</style>
